
import { getCurrentTime } from 'pages/chat/RoomItem/getCurrentTime';
import { userImg } from 'pages/users/catalog/UsersItem/userImg';
import { Link } from 'react-router-dom';

const MessagesHead = ({ currentUserInRoom }) => {
  if (!currentUserInRoom) { return false }

  return (
    <div className='message-head rooms-item'>
      <Link to={`/users-catalog/${currentUserInRoom.uid}`}>
        <div
          className="rooms-item-face img-use-bg" style={userImg(currentUserInRoom)}>
        </div>

        <div className="rooms-item-info">
          <div className="rooms-item-name">
            {currentUserInRoom.name}
          </div>
          <span className="rooms-item-date">
            {getCurrentTime(currentUserInRoom)}
          </span>
        </div>
      </Link>
    </div>
  )
}





export default MessagesHead;