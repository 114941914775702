import { useState, useEffect, useRef } from 'react'
import { calculateAge } from 'pages/users/hooks/calculateAge';

import ListUsers from 'pages/users/catalog/ListUsers';

import { connect } from 'react-redux';

import UsersSearchPanel from 'pages/users/catalog/UsersSearchPanel';

import { toCaseCount } from 'pages/hotels/hooks/toCaseCount'
import axios from 'axios';





const UserCatalog = ({ account }) => {

  const gridRef = useRef(null);

  const [allUsers, setAllUsers] = useState([]); // Все пользователи

  const [displayedUsers, setDisplayedUsers] = useState([]); // Пользователи для отображения

  const [filterUsers, setFilterUsers] = useState([]); // Пользователи для отображения

  const [loading, setLoading] = useState(true);

  const limit = 27; // Количество записей на одну страницу

  const [loadedCount, setLoadedCount] = useState(limit); // Текущая страница

  const [total, setTotal] = useState(0); // Общее количество пользователей


  useEffect(() => {
    // Загружаем данные при монтировании компонента
    loadUsers();
  }, []);


  useEffect(() => {
    // Обновляем отображаемых пользователей при изменении фильтра
    setDisplayedUsers(filterUsers.slice(0, loadedCount));
    setTotal(filterUsers.length);
  }, [filterUsers, loadedCount]);

  // Обработчик скроллинга с использованием debounce
  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  useEffect(() => {
    const handleScroll = debounce(() => {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

      // const windowHeight = window.innerHeight; // Высота окна



      const containerHeight = gridRef.current.offsetHeight;

      console.log('clientHeight', scrollHeight, scrollTop, containerHeight, clientHeight);

      if ((scrollTop + clientHeight) > (containerHeight + 300) && !loading && displayedUsers.length < filterUsers.length) {
        setLoadedCount((prev) => prev + limit);
      }
    }, 300);

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);


  }, [loading, displayedUsers, filterUsers]);

  const loadUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://hotpal.ru/api/base/vendor/list.php`);
      const { data } = response;

      setAllUsers(data);
      setFilterUsers(data); // Показать первую порцию данных

    } catch (error) {
      console.error('Ошибка загрузки данных:', error);
    } finally {
      setLoading(false);
    }
  };


  // const loadMoreUsers = () => {
  //   // Увеличиваем лимит и показываем больше пользователей
  //   setLoadedCount((prev) => prev + limit);
  // };

  // if (loading) return 'Loading...';

  return (
    <>
      <div className="stub"></div>

      <UsersSearchPanel
        allUsers={allUsers}
        setFilterUsers={setFilterUsers}
      />

      <div className="main-full total-count">
        Найдено всего: <span>{total} {toCaseCount(total)}</span>
      </div>
      <div className="catalog-grid main-grid" ref={gridRef}>
        <ListUsers displayedUsers={displayedUsers} account={account} />

      </div>

      {/* <div className='btn btn--blue' onClick={loadMoreUsers}>+</div> */}

      {loading && (
        <div className="preloader-container"><div className="preloader"></div></div>
      )}
    </>
  )
}


const mapStateToProps = (state) => {
  return {
    account: state.account
  }
}

export default connect(mapStateToProps)(UserCatalog);