
import { connect } from 'react-redux';
import axios from 'axios';

import ActionFn from 'store/actions';
import EmptyRoom from 'pages/chat/EmptyRoom';

import RoomItem from 'blocks/controls-panel/chat/RoomItem';

const RoomList = ({
  uid,
  rooms,
  ActionFn,
  // currentRoomPanel,
  // currentUserInRoomPanel,
  // setCurrentRoomPanel,
  // setCurrentUserInRoomPanel
}) => {


  const onChoiseRoom = (room, user) => {
    console.log('currentUserInRoom', room)
    // setCurrentRoomPanel(room);
    // setCurrentUserInRoomPanel(user)

    ActionFn('SET_GLOBAL', { currentRoomPanel: room, currentUserInRoomPanel: user });
  }



  const onDeleteRoom = async (id) => {

    const response = axios.post("https://hotpal.ru:5000/room/delete", {
      "_id": id
    });

    if (response) {
      ActionFn('SET_GLOBAL', {
        rooms: rooms.filter(room => room._id !== id),
      });
    }

  }


  const sortedRooms = [...rooms].sort((a, b) => {

    const countUnreadA = a.messages.filter(msg => !msg.read && msg.uid !== uid).length;
    const countUnreadB = b.messages.filter(msg => !msg.read && msg.uid !== uid).length;

    // 1. Сначала сортируем по наличию непрочитанных сообщений
    if (countUnreadA > 0 && countUnreadB === 0) {
      return -1; // a идет раньше
    }
    if (countUnreadB > 0 && countUnreadA === 0) {
      return 1; // b идет раньше
    }

    // 2. Если обе комнаты имеют непрочитанные сообщения или обе их не имеют
    // Сортируем по `updatedAt` (более поздняя дата выше)
    return new Date(b.updatedAt) - new Date(a.updatedAt);
  });

  return (
    <div className='chat-rooms'>
      {rooms.length ? sortedRooms.map((room) => <RoomItem
        room={room}
        key={room._id}
        // roomId={currentRoomPanel}

        uid={uid}


        onChoiseRoom={onChoiseRoom}
        onDeleteRoom={onDeleteRoom}
      />) : <EmptyRoom />}

    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    rooms: state.globalState.rooms,
  }
}


export default connect(mapStateToProps, { ActionFn })(RoomList);