
export const userImg = (user) => {

  if (user.imgsAccount) {
    let img = user.imgsAccount;

    if (typeof img === 'string') {
      try {
        img = JSON.parse(img);
      } catch (error) {
        // console.error('Error parsing user.imgsAccount:', error);
        console.log('err img');

      }
    }

    if (Array.isArray(img) && img.length > 0) {
      const encodedUrl = img[0].url.replace(/\s/g, '%20');
      return { backgroundImage: `url(${encodedUrl})` };
    }
  }
}


