
const GoalsUsers = ({ user }) => {

  const { goals } = user;


  let stringGoals = goals;
  // Приведение строки к корректному формату JSON
  stringGoals = stringGoals.replace(/<i class="(.*?)"><\/i>/g, '').trim();

  let goalsJson = [];
  try {
    goalsJson = Array.isArray(stringGoals) ? stringGoals : JSON.parse(stringGoals);
  } catch (error) {
    console.error('Invalid goals format:', error);
    return null;
  }
  return (
    <div className="tags-container">
      <h3>Цели:</h3>
      {(goalsJson === null || goalsJson === undefined || goalsJson.length === 0) ? 'Cписок целей пуст' : goalsJson.map((goal, index) => (
        <div className="tag" key={index}><span dangerouslySetInnerHTML={{ __html: goal }}></span> </div>
      ))
      }
    </div>
  )
}

export default GoalsUsers