import { renderGoals } from 'pages/users/hooks/renderGoals';

const GoalsUsers = ({ user, account }) => {
  const { goals, setting_goals } = user;

  // Проверка на отсутствие целей или несоответствие ориентации
  if (!goals || goals === 'null' || (setting_goals && setting_goals !== account.orientation)) {
    return null;
  }

  let stringGoals = goals;
  // Приведение строки к корректному формату JSON
  stringGoals = stringGoals.replace(/<i class="(.*?)"><\/i>/g, '').trim();

  // Обработка целей: массив или строка в формате JSON
  let goalsJson = [];
  try {
    goalsJson = Array.isArray(stringGoals) ? stringGoals : JSON.parse(stringGoals);
  } catch (error) {
    console.error('Invalid goals format:', error);
    return null;
  }

  // Если массив целей пуст, ничего не рендерим
  if (goalsJson.length === 0) {
    return null;
  }

  return (
    <div className="goals-users">
      {goalsJson.map((item, index) => (
        <div key={index}>
          <div className="goals-users-tag">{renderGoals(item)}</div>
        </div>
      ))}
    </div>
  );
};

export default GoalsUsers;
