import UserItem from 'pages/users/catalog/UsersItem';

const ListUsers = ({ displayedUsers, account }) => {
  return (
    <>
      {displayedUsers.map((user, index) => {
        // console.log('user', user.imgsAccount)
        if (user.imgsAccount === '[]') { return false; }

        return (

          <UserItem
            user={user}
            account={account}
            key={index}
          />

        )
      })}
    </>
  )
}

export default ListUsers