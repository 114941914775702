


import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { userImg } from 'pages/users/catalog/UsersItem/userImg';
// import HotelUser from 'pages/users/catalog/UsersItem/HotelUser';
import GoalsUsers from 'pages/users/catalog/UsersItem/GoalsUsers';
import NameUsers from 'pages/users/catalog/UsersItem/NameUsers';
import Btns from 'pages/users/catalog/UsersItem/Btns';

import { getCurrentTime } from 'pages/chat/RoomItem/getCurrentTime';


const UserItem = ({
  user,
  dateTravel,
  account,
  searchListing
}) => {


  const [imageExists, setImageExists] = useState(true);

  useEffect(() => {
    const img = new Image();
    let imgJson;

    if (typeof user.imgsAccount === 'string') {
      try {
        imgJson = JSON.parse(user.imgsAccount);
      } catch (error) {
        console.log('err img');
      }
    }

    img.src = imgJson[0]?.url;
    img.onload = () => {
      setImageExists(true)
      // console.log('img', user.imgsAccount, true)
    };  // Изображение существует
    img.onerror = () => {
      setImageExists(false)
      // console.log('img', user.imgsAccount, false)
    }; // Изображение не существует

  }, []);

  if (!imageExists) {
    return false;
  }

  return (
    <div className="col-4 col-md-6 col-xs-12">
      <div className="users-item" >
        <div className="users-item-img img-use-bg" style={userImg(user)}></div>
        <div className="users-item-bg" ></div>
        <div className="users-item-info">

          <Link className='users-item-link' to={`/users-catalog/${user.uid}`}></Link>

          <GoalsUsers
            user={user}
            account={account}
          />

          <NameUsers
            user={user}
          />

          {account.uid !== user.uid && (<Btns
            user={user}
            account={account}
          // uid={account.uid}
          // searchListing={searchListing}
          />)}

          {user.entranceDate && (<div className="user-catalog-entrance">
            {getCurrentTime(user)}
          </div>)}

        </div>
        {dateTravel && <div className='user-date-travel'><div>Даты нахождения в отеле:</div> {dateTravel}</div>}
      </div>
    </div>

  )
}

export default UserItem;
