import { Field } from "redux-form";
import { useState, useEffect, useRef, useCallback } from "react";

const TempateInput = (props) => {
  const {
    input,
    meta: { error },
  } = props;

  const {
    label,
    placeholder,
    text,
    textSecond,
    options = [],
    className,
    subType,
  } = props.obj;

  const [tags, setTags] = useState([]);
  const [filterList, setFilterList] = useState(options);
  const [showPopup, setShowPopup] = useState(false);
  const [term, setTerm] = useState("");
  const inputRef = useRef();

  useEffect(() => {
    if (input.value && typeof input.value === "string") {
      try {
        const parsedValue = JSON.parse(input.value);
        setTags(parsedValue);
        const updatedFilterList = options.filter((item) => !parsedValue.includes(item));
        setFilterList(updatedFilterList);
      } catch (error) {
        console.error("Invalid JSON in input.value", error);
      }
    }
  }, [input.value, options]);

  const updateTags = useCallback(
    (newTags) => {
      setTags(newTags);
      console.log('tages', JSON.stringify(newTags))
      input.onChange(JSON.stringify(newTags));
    },
    [input]
  );

  const onTagsAdd = useCallback(
    (item) => {
      if (!tags.includes(item)) {
        updateTags([...tags, item]);
        setFilterList(filterList.filter((el) => el !== item));
      }
    },
    [tags, filterList, updateTags]
  );

  const onRemoveTags = useCallback(
    (item) => {
      updateTags(tags.filter((el) => el !== item));
      setFilterList([...filterList, item]);
    },
    [tags, filterList, updateTags]
  );

  const addOwn = useCallback(() => {
    if (term.trim()) {
      updateTags([...tags, term]);
      setTerm("");
      setFilterList(options.filter((el) => ![...tags, term].includes(el)));
    }
  }, [term, tags, options, updateTags]);

  const filterOptions = useCallback(
    (e) => {
      const searchValue = e.target.value.toLowerCase();
      setTerm(searchValue);
      setFilterList(
        options.filter((el) =>
          el.toLowerCase().includes(searchValue)
        )
      );
    },
    [options]
  );

  return (
    <div className={`tags-add-container ${className}`}>
      <div className="tags-container">
        <h3>{label}:</h3>
        <div className="tags-added">
          {tags.length === 0
            ? "Список пуст"
            : tags.map((item, index) => (
              <span
                key={index}
                className="tag"
                onClick={() => onRemoveTags(item)}
              >
                <i dangerouslySetInnerHTML={{ __html: item }}></i>
                <em></em>
              </span>
            ))}
        </div>
      </div>
      <div className="tags-add-input">
        <div className="tags-add-hint">
          <h3>{text}</h3>
          <div className="tags-add-hint-text">{textSecond}</div>
        </div>
        <div className={`${showPopup ? "active" : ""} input-box tags-search`}>
          <input
            className="input-decorate"
            type="text"
            placeholder={placeholder}
            ref={inputRef}
            value={term}
            onChange={filterOptions}
            onBlur={() => setShowPopup(false)}
            onFocus={() => setShowPopup(true)}
          />
          <i></i>
          {filterList.length > 0 && !subType && (
            <ul className="tags-popup ln">
              {filterList.map((item, index) => (
                <li
                  key={index}
                  onClick={() => onTagsAdd(item)}
                  dangerouslySetInnerHTML={{ __html: item }}
                ></li>
              ))}
            </ul>
          )}
        </div>
        {subType && (
          <button className="btn btn--blue" onClick={addOwn}>
            Добавить интерес
          </button>
        )}
      </div>
    </div>
  );
};

const EditionTags = ({ obj }) => {
  return (
    <Field
      name={obj.name}
      obj={obj}
      component={TempateInput}
    />
  );
};

export default EditionTags;
