import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

import { Link } from "react-router-dom";
import "lightgallery.js/dist/css/lightgallery.css";
import { LightgalleryProvider } from "react-lightgallery";
import { LightgalleryItem } from "react-lightgallery";

import { userImg } from 'pages/users/catalog/UsersItem/userImg';
import { useState, useEffect } from "react";

const settings = {
  lazyload: true,
  nav: true,
  controls: false,
  mouseDrag: true,
  loop: true,
  items: 1,
  gutter: 5,
  responsive: {
    420: {
      items: 1
    }
  }
};


const Photos = ({ user }) => {

  // Проверяем наличие изображений у пользователя
  // let imgs = [];
  const [imgs, setImgs] = useState([]);
  const [sliderKey, setSliderKey] = useState(0);


  useEffect(() => {
    // Обновляем изображения, когда изменяется пользователь
    if (user.imgsAccount) {
      let parsedImgs = [];
      if (typeof user.imgsAccount === "object") {
        parsedImgs = user.imgsAccount;
      } else {
        try {
          parsedImgs = JSON.parse(user.imgsAccount);
        } catch (error) {
          console.error("Failed to parse imgsAccount:", error);
        }
      }
      setImgs(parsedImgs || []);
      setSliderKey((prevKey) => prevKey + 1);
    } else {
      setImgs([]);
    }
  }, [user]);


  // Если нет изображений, возвращаем null
  if (!imgs || imgs.length === 0) {
    return null;
  }

  return (
    <div className="user-photo" key={sliderKey} >

      <LightgalleryProvider>
        <TinySlider settings={settings}  >
          {imgs.map((img, index) => (
            <LightgalleryItem group="user-photos" src={img.url} key={index} >
              <a href={img.url} target="_blank" rel="noopener noreferrer">
                <img src={img.url} alt={img} />
                <div
                  className="users-item-img img-use-bg"
                  style={{ backgroundImage: `url(${img.url})` }}
                ></div>
              </a>
            </LightgalleryItem>))}
        </TinySlider>

      </LightgalleryProvider>
    </div>


  )
}

export default Photos